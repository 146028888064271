<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" showOutput :showSearch="true" @getList="getList"
			@clearSearch="clearSearch">
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.phone" size="small" clearable style="width: 140px" placeholder="按手机号"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.vip" size="small" clearable style="width: 100px" placeholder="用户属性">
					<el-option label="VIP" value="1"></el-option>
					<el-option label="非VIP" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.city" size="small" clearable style="width: 100px" placeholder="来源">
					<el-option label="苏州" value="20"></el-option>
					<el-option label="无锡" value="30"></el-option>
				</el-select>
			</el-form-item>

			<!--表格内容-->
			<vxe-table-column slot="table-item" field="fans_id" title="用户ID" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="phone" title="手机号" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="is_vip" title="VIP" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="userNum" title="邀请用户" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="num" title="奖励券数" align="center" min-width="50px">
				<template slot-scope="{ row }">
					<a @click="detailCoupon(row)" style="color:blue;cursor:pointer">{{row.num}}</a>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="reward" title="奖励金额(元)" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="city" title="归属城市" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" title="操作" align="center" width="280">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="detailOne(row)">查看</el-button>
				</template>
			</vxe-table-column>
		</Tables>
		<el-dialog title="邀请详情" :visible.sync="detailShow" :close-on-click-modal="false" :close-on-press-escape="false" width="800px">
			<el-descriptions title="">
				<el-descriptions-item label="用户ID">{{ detailData.fans_id }}</el-descriptions-item>
				<el-descriptions-item label="手机号">{{ detailData.phone }}</el-descriptions-item>
				<el-descriptions-item label="VIP">{{ detailData.is_vip }}</el-descriptions-item>
				<el-descriptions-item label="归属地">{{ detailData.city }}</el-descriptions-item>
			</el-descriptions>
			<el-table :data="detailList" border class="data_table">
				<el-table-column prop="phone" label="手机号" width="110"></el-table-column>
				<el-table-column prop="tm" label="邀请时间" width="150"></el-table-column>
				<el-table-column prop="user_type" label="用户类型" width="80">
					<template v-slot="{ row }">
						{{ userType[row.user_type] }}
					</template>
				</el-table-column>
				<el-table-column prop="city" label="归属地" width="70"></el-table-column>
				<el-table-column prop="is_vip" label="VIP" width="50" />
				<el-table-column prop="inviteStatus" label="邀请状态">
				</el-table-column>
				<el-table-column prop="ext" label="15度达成时间" width="160">
					<template v-slot="{ row }">
						{{ row.ext[0] }}
					</template>
				</el-table-column>
				<el-table-column prop="ext" label="30度达成时间" width="160">
					<template v-slot="{ row }">
						{{ row.ext[1] }}
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
		<el-dialog title="卡券列表" :visible.sync="couponShow" :close-on-click-modal="false" :close-on-press-escape="false" width="800px">
			<el-table :data="couponList" border class="data_table">
				<el-table-column prop="coupon_id" label="ID" width="180"></el-table-column>
				<el-table-column prop="coupon_name" label="名称" width="180"></el-table-column>
				<el-table-column prop="start_time" label="开始时间" width="180"></el-table-column>
				<el-table-column prop="end_time" label="结束时间" width="180"></el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'market-activitycommon-log',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '邀请有礼用户',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {
				},
				userType:{"1":"新注册","2":"回归用户"},
				// 详情
				detailShow: false,
				detailData: {},
				detailList: [],
				detailTotal: 0,
				//卡券
				couponShow: false,
				couponList: [],
			}
		},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					activity_id: this.$route.query.id,
					page: currentPage,
					size: pageSize
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Market.ActivityCommon.inviteLog(params)
				this.tableData = res.data;
				this.totalPage = res.total;
				this.isLoading = false;
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			async detailOne(row){ //查看详情
				this.detailShow = true;
				this.detailData = row;

				let params = {
					token: this.$store.state.user.token,
					activity_id: this.$route.query.id,
					fans_id: row.fans_id,
					page: 1,
					size: 1000
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Market.ActivityCommon.inviteLog(params);
				this.detailList = res.data;
				for(let v of this.detailList){
					if(v.reward > 0){
						v.inviteStatus = "已成功";
					}else if(Date.now() - new Date(v.tm).getTime() < 3 * 24 * 3600 * 1000){
						v.inviteStatus = "邀请中";
					}else{
						v.inviteStatus = "已失败";
					}
				}
				this.detailTotal = res.total;
				this.isLoading = false;
			},
			async detailCoupon(row) {
				const params = {
					token: this.$store.state.user.token,
					fans_id: row.fans_id,
					source: 3
				}
				const res = await this.$api.Fans.GetConDetail(params);
				this.couponList = res;
				this.couponShow = true;
			},
		}
	}
</script>

<style lang="scss" scoped>
	.row-title {
		font-size: 14px;
		line-height: 20px;
		padding: 4px 0;
		margin-bottom: 18px;

		&:before {
			content: " ";
			width: 5px;
			height: 20px;
			background: #008e4d;
			display: block;
			float: left;
			margin: 0 10px 0 0;
		}
	}
</style>
